import { useWindowSize } from '@vueuse/core';
import { computed } from 'vue';

export enum Breakpoints {
  sm = 768,
  md = 992,
  lg = 1200,
  xl = 1500,
}

export default function useScreen () {
  const { width } = useWindowSize();

  function between (min: number, max?: number) {
    return width.value >= min && (max === undefined || width.value < max);
  }

  const isXSmall = computed(() => between(0, Breakpoints.sm));
  const isSmall = computed(() => between(Breakpoints.sm, Breakpoints.md));
  const isMedium = computed(() => between(Breakpoints.md, Breakpoints.lg));
  const isLarge = computed(() => between(Breakpoints.lg, Breakpoints.xl));
  const isXLarge = computed(() => width.value >= Breakpoints.xl);

  return {
    isXSmall,
    isSmall,
    isMedium,
    isLarge,
    isXLarge,
    between,
  };
}
