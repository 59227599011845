<script setup lang="ts">
import { useOnline } from '@vueuse/core';

const isOnline = useOnline();
const { dispatchMessage } = useSystemMessages();

watch(isOnline, (newValue: boolean) => {
  if (newValue) {
    dispatchMessage('Back online.', 'success', 'Your browser is back online.', 10000);
    return;
  }

  dispatchMessage('You are offline.', 'warn', 'Your browser went offline, please check your internet connection.', 15000);
});
</script>

<template>
  <div class="layout-application">
    <AppHeader />
    <SystemMessages />

    <slot />
  </div>
</template>
