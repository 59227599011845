<script setup lang="ts">
import useCreateLink from '~/composables/useCreateLink';
import useScreen, { Breakpoints } from '~/composables/useScreen';
import type { MenuItem } from '~/mui/src/components/MNavigation/MNavigationItem.vue';
import { SubscriptionType } from '~/composables/useAuthentication';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { GROUP_MEMBER_ROLE, GROUP_MEMBER_STATUS } from "~/enums/group.enums";
import { GenericEvents } from '~/enums/tracking/tracking-events.enums';

const { between } = useScreen();
const route = useRoute();
const isMenuExpanded = ref(false);
const { user } = useUserStore();
const { dispatchTrackingEvent } = useTracking();

const activeItem = computed(() => {
  const routeBase = route.path.split('/')[1];
  const lastPartOfTheRoute = route.path.split('/').pop();

  const moreChildrenIds = menuItems.value.find(entry => entry.id === 'more')
    ?.children?.map(entry => entry.id);

  return moreChildrenIds?.includes(routeBase) ? 'more' : lastPartOfTheRoute;
});

const removeIcon = (item: MenuItem) => {
  const newItem = { ...item };
  delete newItem.icon;
  return newItem as MenuItem;
};

const { createMedmasteryLink } = useCreateLink();

const openMenu = () => {
  isMenuExpanded.value = true;
  document.body.classList.add('no-scroll');
};

const closeMenu = () => {
  isMenuExpanded.value = false;
  document.body.classList.remove('no-scroll');
};

const NuxtLinkComponent = resolveComponent('NuxtLink');

const menuItemsMain = computed(() => {
  const items = [
    {
      label: 'Dashboard',
      id: 'dashboard',
      href: '/dashboard',
      icon: 'fa-graduation-cap',
      element: NuxtLinkComponent,
    },
    {
      label: 'Course library',
      id: 'courses',
      href: '/courses',
      icon: 'fa-books',
      element: NuxtLinkComponent,
    },
    {
      label: 'AI Prompt',
      id: 'ai-prompting-tool',
      href: '/tools/ai-prompting-tool',
      icon: 'fa-hammer',
      element: NuxtLinkComponent,
    },
  ];

  const hasAccessToGroupsPage = user.value?.group?.groupUuid &&
      user.value?.group?.memberStatus === GROUP_MEMBER_STATUS.ACTIVE &&
      user.value?.group?.memberRole !== GROUP_MEMBER_ROLE.MEMBER;
  if (hasAccessToGroupsPage) {
    items.unshift(  {
      label: 'Group',
      id: 'groups',
      href: '/groups',
      icon: 'fa-user-group',
      element: NuxtLinkComponent,
    });
  }

  return items;
});

const menuClick = (url: string | undefined, text?: string | ((...args: any) => string), category?: string | undefined) => {
  dispatchTrackingEvent(GenericEvents.MenuClick, {
    link_url: url,
    click_text: text,
    menu_category: category,
  });
};

const menuItemsMore = computed<MenuItem[]>(() => {
  const items: MenuItem[] = [
    {
      label: 'Podcasts',
      id: 'podcasts',
      href: createMedmasteryLink('/podcasts'),
      target: '_blank',
    },
    {
      label: 'Magazine',
      id: 'magazine',
      href: createMedmasteryLink('/magazine'),
      target: '_blank',
    },
    {
      label: 'Guides',
      id: 'guides',
      href: createMedmasteryLink('/guides'),
      target: '_blank',
    },
    {
      label: 'Webinars',
      id: 'webinars',
      target: '_blank',
      command: () => {
        navigateTo('/webinars');
      },
    },
    {
      label: 'Feedback',
      id: 'feedback',
      command: () => leaveFeedback(),
    },
  ];

  if (user.value?.planType === "trial") {
    items.unshift(    {
      label: 'Pricing',
      id: 'pricing',
      command: () => {
        navigateTo('/pricing');
      },
    });
  }

  return items;
});

const menuItems = computed(() => {
  return [
    ...menuItemsMain.value.map(removeIcon),
    {
      label: 'More',
      id: 'more',
      children: [
        ...menuItemsMore.value,
      ],
    },
  ];
});

const menuItemsMobile = computed(() => {
  return [
    ...menuItemsMain.value,
    ...menuItemsMore.value,
  ];
});

const leaveFeedback = () => {
  useNuxtApp().$openFreshdeskWidgetForm();
  isMenuExpanded.value = false;
};

</script>

<template>
  <template v-if="between(0, Breakpoints.lg)">
    <BurgerMenuToggle
      :open="isMenuExpanded"
      @click="isMenuExpanded ? closeMenu() : openMenu()"
    />

    <Transition name="height">
      <div
        v-if="isMenuExpanded"
        class="touch-navigation-dropdown"
        @click="closeMenu"
      >
        <Menu
          :model="menuItemsMobile"
          :active-item="activeItem"
        >
          <template #item="{item}">
            <template v-if="item.href?.startsWith('http')">
              <a
                class="item"
                :href="item.href"
                :target="item.target"
                @click="menuClick(item.url, item.label)"
              >
                <span class="p-menuitem-text">
                  {{item.label}}
                </span>
                <FontAwesomeIcon
                  v-if="item.icon"
                  :icon="item.icon"
                />
              </a>
            </template>

            <template v-else>
              <NuxtLink
                class="item"
                :to="item.href"
                @click="menuClick(item.url, item.label)"
              >
                <span class="p-menuitem-text">
                  {{item.label}}
                </span>
                <FontAwesomeIcon
                  v-if="item.icon"
                  :icon="item.icon"
                />
              </NuxtLink>
            </template>
          </template>

          <template
            v-if="user?.subscription.type === SubscriptionType.trial"
            #end
          >
            <NuxtLink to="/pricing">
              <Button
                label="Get full access"
                :pt="{
                  label: {
                    class: '!text-nowrap'
                  }
                }"
                class="bg-yellow-50 text-neutral-80 border-none text-nowrap rounded-lg justify-center w-full"
              />
            </NuxtLink>
          </template>
        </Menu>
      </div>
    </Transition>
  </template>

  <!-- desktop -->
  <template v-else>
    <MNavigationBar
      :items="menuItems"
      :active-item="activeItem"
    />
  </template>
</template>

<style lang="scss">
@import "@mui/assets/styles/utility.scss";
@import "@mui/assets/styles/font-styles.scss";

body.no-scroll {
  overflow: hidden !important;
}

@keyframes expand {
  from {
    max-height: 0;
  }
  to {
    max-height: calc(100svh - var(--header-offset));
  }
}

@keyframes collapse {
  from {
    max-height: calc(100svh - var(--header-offset));
  }
  to {
    max-height: 0;
  }
}

.height-enter-active {
  animation: expand 0.2s ease forwards;
}

.height-leave-active {
  animation: collapse 0.2s ease forwards;
}

.touch-navigation-dropdown {
  --header-offset: calc(86px + 1px);

  position: fixed;
  width: 100vw;
  height: calc(100svh - var(--header-offset));
  overflow: hidden;
  top: var(--header-offset);
  left: 0;
  z-index: 200;
  transition: height 0.3s ease;

  .p-button {
    @include f-16-bold;
  }

  .p-menu {
    position: relative;
    height: 100%;
    z-index: 2;
    border-radius: 0;
    border: none;
    padding-bottom: 0;
    padding-top: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .p-menu-list {
      border-radius: 0.5rem;
      overflow: hidden;
      margin: 0 1rem;
    }

    .item {
      display: flex;
      justify-content: space-between;
      color: var(--palette-neutral-90);
      @include f-20-regular;
      padding: 1rem;
    }

    .p-menuitem {
      border-bottom: 1px solid var(--palette-neutral-10);

      &:last-child {
        border-bottom: none;
      }
    }

    .p-menu-end {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2);
      padding: var(--spacing-8);
    }
  }
}
</style>
